import React, { cloneElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import authRoutes from '../../configs/admin/admin-routes';
import { Spinner } from 'reactstrap';
import HocPageSpin from './HocPageSpin';
import HocLoginCounter from './HocLoginCounter';

const loading = () => (
    <div className="animated fadeIn pt-3 text-center">
        <Spinner style={{ marginTop: '50px', marginBottom: '50px' }} color="dark" />
    </div>
);

const AppContent = props => {
    const BindingHocElement = HocPageSpin(HocLoginCounter((propsFromHoc) => (
        <Routes forceRefresh={false}>
            {
                authRoutes.map((route, i) =>
                    route.children ? 
                    (
                        route.children.map((pet, index) => 
                            <Route 
                                key={index} 
                                element={cloneElement(pet.element, {...propsFromHoc})} 
                                path={pet.path} {...props} 
                            />
                        )
                    ) : ( <Route 
                            key={i} 
                            path={route.path}
                            element={cloneElement(route.element, {...propsFromHoc})} 
                        />
                    )
                )
            }
        </Routes>
    )));

    return (
        <React.Suspense fallback={loading()}>
            <BindingHocElement
                {...props}
            />
        </React.Suspense>
    );
}

export default AppContent;